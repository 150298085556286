export const GTM_EVENT = {
  CLAIM_NO_EVENT: 'MyAsurion_DL_Event Claim No',
  INCIDENT_TYPE_EVENT: 'MyAsurion_DL_Event Incident Type',
  TRIAGE_SCREEN_EVENT: 'MyAsurion_DL_Event Triage Screen',
  TRIAGE_SELECTION_EVENT: 'MyAsurion_DL_Event Triage Selection',
  OEM_SCREEN_EVENT: 'MyAsurion_DL_Event OEMWarranty Screen',
  SET_FULFILLMENT_EVENT: 'MyAsurion_DL_Event Fulfillment Option',
  CANCEL_CLAIM_EVENT: 'MyAsurion_DL_Event Cancel Claim',
  REPLACEMENT_SCREEN_EVENT: 'MyAsurion_DL_Event Replacement Screen',
  REPLACEMENT_CALL_EVENT: 'MyAsurion_DL_Event Replacement Call',
  DEVICE_SELECTION_SCREEN_EVENT: 'MyAsurion_DL_Event Device Selection Screen',
  SERVICE_ORDER_EVENT: 'MyAsurion_DL_Event Service Order',
  FMIP_SCREEN_EVENT: 'MyAsurion_DL_Event FMIP Screen',  
  TNC_SCREEN_EVENT: 'MyAsurion_DL_Event TNC Screen',
  LOGISTIC_SCREEN_EVENT: 'MyAsurion_DL_Event Logistic Screen',
  REPRESENTATIVE_SCREEN_EVENT: 'MyAsurion_DL_Event Representative Screen',
  UPDATE_SHIPPING_EVENT: 'MyAsurion_DL_Event Update Shipping',
  REVIEW_SUBMISSION_SCREEN_EVENT: 'MyAsurion_DL_Event Review Submission Screen',
  PAYMENT_SCREEN_EVENT: 'MyAsurion_DL_Event Payment Screen',
  CREATE_REPAIR_REQUEST_EVENT: 'MyAsurion_DL_Event Create Repair Request',
  CHARGE_ORDER_EVENT: 'MyAsurion_DL_Event Charge Order',
  SUBMIT_ORDER_EVENT: 'MyAsurion_DL_Event Submit Order',
  CLAIM_CONFIRMATION_SCREEN_EVENT: 'MyAsurion_DL_Event Claim Confirmation Screen',
  MYCLAIMS_SCREEN_EVENT: 'MyAsurion_DL_Event Myclaims Screen',
  UPDATE_DEVICE_SCREEN_EVENT: 'MyAsurion_DL_Event Update Device',
  IMEI_DETAILS_SCREEN_EVENT: 'MyAsurion_DL_Event IMEI Details Screen',
  SELECT_MAKE_MODEL_SCREEN_EVENT: 'MyAsurion_DL_Event Select Make Model Screen',
  UPDATE_DEVICE_REVIEW_SCREEN_EVENT: 'MyAsurion_DL_Event Update Device Review Page',
  UPDATE_DEVICE_CONFIRMATION_SCREEN_EVENT: 'MyAsurion_DL_Event Confirm Update Device Screen'
};

export const INCIDENT_TYPES = {
  DEVICE_REFRESH: 'Device Refresh',
  BATTERY_REPLACEMENT: 'Battery Replacement',
  FAULT_REPAIR: 'Fault Repair',
  SWAP: 'SWAP',
  MALFUNCTION: 'Malfunction',
  MALFUNCTION_IW: 'Malfunction-IW',
  MALFUNCTION_EWS: 'Malfunction-EWS',
  REPLACEMENT: 'REPLACEMENT',
  SCREEN_REPAIR: 'Screen Repair',
  SCREENREPAIR_BATTERYREPLACEMENT: 'Bat Scr Replacement',
  SCREEN_REPAIR_WARRANTY: 'Screen Repair Warranty',
  DEVICE_REPAIR: "Device Repair",
  CLEANING_SERVICE: 'CLEANING_SERVICE',
};

export const LOGISTICS_TYPES = {
  WALKIN: 'Walk-in',
  PUR: 'Pick-up and return',
  DELIVERY: 'Delivery',
  VAS: 'VAS',
};

export const FULLFILLMENT_TYPES = {
  WALKIN: 'WALKIN',
  PUR: 'PUR',
  DELIVERY: 'Delivery',
  VAS: 'VAS'
};

export const POST_RETRY_APIS = [
  'swap/claim/api/shippingorder',
  'swap/claim/api/submitorder',
  'swap/claim/api/fulfillmentoptions',
  'swap/claim/api/fmipcheckeligibility',
  'claim/api/contactpoints',
  '/swap/claim/api/v3/shippingmethods',
  'swap/claim/api/cancelservicerequest',
  'swap/claim/api/getscheduledavailability',
  '/swap/claim/api/v2/getaspstorelist',
];

export const CONTENTFUL_SLUG = {
  APPLE_VIDEO: 'myasurion-base-swap-videopage-apple-video',
  ANDROID_VIDEO: 'myasurion-base-swap-videopage-samsung-video',
  FMIP_STEP1: 'fmipstep1',
  FMIP_STEP2: 'fmipstep2',
  FMIP_STEP3: 'fmipstep3',
  VISA: 'visa',
  GOOGLE_PAY: 'googlepay',
  PAYPAL: 'paypal',
  APPLE_PAY: 'applepay',
  LAPTOP: 'laptop',
  LAUNDRYCLOSET: 'laundrycloset',
  MASSAGEEQUIPMENT: 'massagechair',
  KITCHENAPPLIANCES: 'microwave',
  BEAUTYCARE: 'beautyequipment',
  DEHUMIDIFIERHEATER: 'portable-electric-heater',
  PURIFIERVACCUM: 'vaccumcleaner',
  MOBILE: 'tablet',
  TABLET: 'tablet',
  TV: 'tv',
  GAMECONSOLE: 'gamingsystem',
  HEALTHEXERCISE: 'smartwatch',
  HEADPHONES: 'headphones',
  PHONE: 'phone'
};

export const DOMAIN = {
  REPAIR: 'repair',
  DP: 'device_protection',
};

export const TRIAGE_QUESTION_TYPE = {
  TRIAGE: 'Triage',
  IN_WARRANTY: 'InWarranty',
  IN_WARRANTY_OTHER: 'InWarrantyOther',
  SYMPTOMS: "Symptoms"
};

export const WARRANTY_TYPE = {
  IW: 'IW',
  IW_OEM: 'IW-OEM',
  HI: 'HI'
};

export const FULLFILLMENT_METHOD_TYPE = {
  ADVEXCH: "ADVEXCH",
  REPAIR: "REPAIR",
  BTRREP: "BTRREP",
  SCRNREP: "SCRNREP"
};

export const REPAIR_FULLFILLMENT_METHOD_TYPE = {
  ONSITE: "ONSITE"
};

export const PICKUP_ELIGIBLE_REPAIR_STATUS = [
  "SCHEDULED",
  "PICKUP FAILED",
  "PICKUP RESCHEDULED",
  "PICKUP INPROGRESS"
]

export const RETURN_ELIGIBLE_REPAIR_STATUS = [
  "AWAITING DELIVERY SCHEDULE",
  "DELIVERY SCHEDULE REQUESTED",
  "DELIVERY RESCHEDULED ",
  "DELIVERY FAILED",
  "AWAITING CLAIM COMPLETION"
]

export const CANCEL_RETURN_ELIGIBLE_REPAIR_STATUS = [
  "AWAITING RETURN SCHEDULE",
  "RETURN SCHEDULE REQUESTED",
  "AWAITING CLAIM CANCELLATION",
  "RETURN FAILED",
]

export const WORKER_URL_PDF =
  "https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js";

export const TCAT_JOB_MODE = {
  PICKUP : "pickup",
  DELIVERY : "delivery",
  RETURN : "return"
}

export const TIME_SLOT_TYPE = {
  PICKUP : "PickUp",
  DELIVERY : "Delivery",
  RETURN : "Return"
}

export const LOCALE = {
  ENGLISH : "en-US",
  CHINESE : "zh-TW",
  THAI : "th-TH"
}

export const CATEGORY = {
  LAPTOP: 'LAPTOP',
  TABLET: 'TABLET',
  HEADPHONES: 'HEADPHONES',
  PHONE: 'PHONE',
  SMARTWATCHES: 'SMARTWATCHES',
  EARPHONES: 'EARPHONES',
  GAMECONSOLES:'GAME CONSOLES'
};

export const CLEANING_SERVICE_EDIT_FIELDS = {
  BRAND: "Brand",
  MODEL: "Model",
  ADDRESS : "Address",
  REGISTERED_NUMBER: "RegisteredNumber",
  ALTERNATE_CONTACT_NUMBER : "AlternateContactNumber",
  DATE_TIME : "DateTime",
  REQUESTED_SERVICE : "Service"
}

export const CLEANING_SERVICE_INVOICE_TYPE = {
  EC_PAY: "EC_PAY",
  MOBILE_BARCODE: "MOBILE_BARCODE",
  CITIZEN_DIGITAL : "CITIZEN_DIGITAL"
}

export const CLEANING_SERVICE_ACTION = {
  ACTION_PROCESS_INCIDENT: "processincident",
  ACTION_CREATE_SHIPPING: "createshippingorder",
}

export const CLEANING_SERVICE_STATUS = {
  PENDING_SCHEDULE_CONFIRMATION : "PENDING SCHEDULE CONFIRMATION",
  SCHEDULE_CONFIRMED : "SCHEDULE CONFIRMED",
  SCHEDULE_REJECTED : "SCHEDULE REJECTED"
}

export const CANCEL_CLEANING_SERVICE_ELIGIBLE_STATUS = [
  CLEANING_SERVICE_STATUS.PENDING_SCHEDULE_CONFIRMATION,
  CLEANING_SERVICE_STATUS.SCHEDULE_CONFIRMED,
  CLEANING_SERVICE_STATUS.SCHEDULE_REJECTED
]
